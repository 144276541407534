import React from "react"
import cx from "classnames"
import SEO from "components/seo"

import Heading from "components/Heading"
import Quote from "components/Quote"
import VideoWrapper from "components/VideoWrapper"
import HeroImageFull from "components/HeroImageFull"
import SlideshowSwiper from "components/SlideshowSwiper"

const BettyTranBrandPage = ({pageData}) => {
  const {
    data: { page, heading, shows, quoteOne, quoteTwo },
  } = pageData;

  return (
    <React.Fragment>
      <SEO title={page} description={quoteOne} />
      <div className="max-container real-hero wrapper--bt-brand--hero">
        <HeroImageFull
          heroTextClassname="mobile--is-center"
          imageName="bettyTranBrandHeroImage"
          imageProps={{
            imgStyle: {
              objectPosition: "center top",
            },
          }}
        >
          <Heading className="has-text-centered is-heading">{heading}</Heading>
        </HeroImageFull>
      </div>
      <div className="max-container wrapper--bt-brand--block-1 bg-grey max-quote">
        <div className="container max-1048">
          <Quote>
            <p>{quoteOne}</p>
          </Quote>
        </div>
      </div>
      {shows.map(({ name, gallery, youtubeId }, index) => (
        <div
          key={`single-show-${index}`}
          className="max-container wrapper--bt-brand--block-show"
        >
          <div className={cx(`wrapper--slideshow-block container`)}>
            {name && (
              <Heading
                className={cx({
                  "mb-0": Boolean(!gallery),
                })}
              >
                {name}
              </Heading>
            )}
            {gallery && (
              <SlideshowSwiper gallery={pageData[gallery]} codeOrder={index} />
            )}
          </div>
          <VideoWrapper videoId={youtubeId} />
        </div>
      ))}

      <div className="max-container wrapper--bt-brand--block-n bg-grey max-quote">
        <div className="container max-1048">
          <Quote>
            <p>{quoteTwo}</p>
          </Quote>
        </div>
      </div>
      {/* <div className="max-container wrapper--bt-brand--block-last">
        <div className={cx(`wrapper--slideshow-block container`)}>
          <SlideshowSwiper gallery={BettyWithCelebs} codeOrder={"last-code"} />
        </div>
      </div> */}
    </React.Fragment>
  )
}

export default BettyTranBrandPage

import React from "react"
import Layout from "components/layout"
import ViewBettyTranBrand from 'components/ViewBettyTranBrand';
import usePageData from "hooks/queryBettyTranBrand"

const BettyTranBrandPage = props => {
  const { pageContext } = props;
  const pageData = usePageData()

  return (
    <Layout pageContext={pageContext}>
      <ViewBettyTranBrand pageData={pageData} />
    </Layout>
  )
}

export default BettyTranBrandPage

import React, { useState, useEffect } from "react"
import cx from "classnames"
import Img from "gatsby-image"
import Swiper from "react-id-swiper"
// import imgSample from "images/pageAbout--hero-2.jpg"
import { motion } from "framer-motion"
import { isMobileViewport } from "utils/utils"

const motionVariants = {
  default: {
    width: "260px",
    height: "390px",
  },
  active: {
    width: "380px",
    height: "560px",
    transition: {
      duration: 0.45,
      ease: "easeInOut",
    },
  },
  activeMobile: {
    width: "300px",
    height: "480px",
    transition: {
      duration: 0.45,
      ease: "easeInOut",
    },
  },
}

const SlideshowSwiper = ({ codeOrder, className, gallery }) => {
  const initSlide = gallery ? Math.floor(gallery.length / 2) : 0
  const isMobile = isMobileViewport()

  const paramsSlideshowSwiper = {
    init: false,
    // resistance: false,
    centeredSlides: true,
    containerClass: "swiper-container slideshow-swiper",
    loop: false,
    slideActiveClass: "swiper-slide-active is-active",
    slidesPerView: 1,
    spaceBetween: 15,
    initialSlide: initSlide,
    width: 260,
    navigation: {
      nextEl: ".swiper-button-next.is-white",
      prevEl: ".swiper-button-prev.is-white",
    },
  }
  const [swiper, updateSwiper] = useState(null)
  const [currentSlideIndex, updateSlideIndex] = useState(initSlide)

  const onClickItem = (clickedIndex) => {
    if (!swiper) return

    swiper.slideTo(clickedIndex)
  }

  useEffect(() => {
    if (!swiper) return
    swiper.on("init", function () {
      this.update()
    })
    swiper.on("transitionStart", function () {
      updateSlideIndex(this.activeIndex)
    })
    swiper.on("transitionEnd", function () {
      // this.update()
    })

    swiper.init();
  }, [swiper])

  return (
    <div className="wrapper--slideshow">
      <Swiper {...paramsSlideshowSwiper} getSwiper={updateSwiper}>
        {gallery.map((value, index) => {
          return (
            <motion.div
              variants={motionVariants}
              initial="default"
              animate={
                Boolean(currentSlideIndex === index) ? isMobile ? "activeMobile" : "active" : "default"
              }
              key={`code-${codeOrder}-${value.childImageSharp.id}`}
              className={cx("slideshow--item")}
              onClick={() => onClickItem(index)}
              onKeyPress={() => onClickItem(index)}
              role="button"
              tabIndex="0"
            >
              <Img
                className={`slideshow--item-image`}
                fluid={value.childImageSharp.fluid}
              />
            </motion.div>
          )
        })}
      </Swiper>
    </div>
  )
}

SlideshowSwiper.defaultProps = {
  codeOrder: 0,
  className: "",
  data: [],
}

export default SlideshowSwiper

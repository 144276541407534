import { useStaticQuery, graphql } from "gatsby"

const usePageData = () => {
  const data = useStaticQuery(
    graphql`
      {
        LAFW2018: allFile(
          filter: { relativeDirectory: { eq: "BettyTranBrand/LAFW 2018" } }
        ) {
          nodes {
            childImageSharp {
              ...BettyFashionImages
            }
          }
        }
        BettyWithCelebs: allFile(
          filter: {
            relativeDirectory: { eq: "BettyTranBrand/BETTY TRAN WITH CELEB" }
          }
        ) {
          nodes {
            childImageSharp {
              ...BettyFashionImages
            }
          }
        }
        DigitalJakartaFw: allFile(
          filter: {
            relativeDirectory: { eq: "BettyTranBrand/DIGITAL JAKARTA FW" }
          }
        ) {
          nodes {
            childImageSharp {
              ...BettyFashionImages
            }
          }
        }
        LAFW2016: allFile(
          filter: { relativeDirectory: { eq: "BettyTranBrand/LAFW 2016" } }
        ) {
          nodes {
            childImageSharp {
              ...BettyFashionImages
            }
          }
        }
        VNFW2017: allFile(
          filter: { relativeDirectory: { eq: "BettyTranBrand/VNFW 2017" } }
        ) {
          nodes {
            childImageSharp {
              ...BettyFashionImages
            }
          }
        }
        MBFW2014: allFile(
          filter: { relativeDirectory: { eq: "BettyTranBrand/MBFW 2014" } }
        ) {
          nodes {
            childImageSharp {
              ...BettyFashionImages
            }
          }
        }
        data: allDataYaml(filter: { codePage: { eq: "betty-tran-brand" } }) {
          edges {
            node {
              ...fragmentBettyTranBrand
            }
          }
        }
      }
    `
  )

  if (!data) return null;

  return {
    LAFW2018: data.LAFW2018.nodes,
    BettyWithCelebs: data.BettyWithCelebs.nodes,
    DigitalJakartaFw: data.DigitalJakartaFw.nodes,
    LAFW2016: data.LAFW2016.nodes,
    VNFW2017: data.VNFW2017.nodes,
    MBFW2014: data.MBFW2014.nodes,
    data: data.data.edges[0].node
  }
}

export default usePageData
